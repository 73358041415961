<template>
    <div class="content-record" style="height:100%">
        <div class="call-record-Style">
            <callSelect
                :doptions="departmentArr"
                :options-member="getStaff"
                :call-type="callType"
                :call-duration="getCallDuration"
                :sort-change="false"
                @departmentIDArray="getDepartment"
                @departmenber="getMenberId"
                @callTypeChange="getCallType"
                @callDurationChange="getCallDurationChange"
            />

            <div class="call-table">
                <div class="call-record-selectStyle-down-all">
                    <div style="width: 40%;">
                        <div v-if="callSearchChange" class="call-search">
                            <el-input v-model="input" placeholder="输入客户姓名/手机号查询" size="mini" />
                            <img
                                src="../../../assets/common/search.png"
                                width="20"
                                height="20"
                                class="call-search-icon"
                                @click="callSearch()"
                            />
                        </div>
                    </div>
                    <div class="call-search-time">
                        <div style="display: flex;align-items: center;">
                            <div style="margin-right: 10px;font-size: 15px;">时间</div>
                            <div>
                                <el-radio-group
                                    v-model="timeTabs"
                                    size="small"
                                    @change="changeTime"
                                >
                                    <el-radio-button label="1">今日</el-radio-button>
                                    <el-radio-button label="2">昨日</el-radio-button>
                                    <el-radio-button label="3">最近一周</el-radio-button>
                                    <el-radio-button label="4">最近一月</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                        <div style="margin-left: 20px;">
                            <el-date-picker
                                v-model="valuetime"
                                size="small"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="timestamp"
                                :default-time="['00:00:00', '23:59:59']"
                                @change="time"
                            />
                        </div>
                    </div>
                </div>
                <div style="margin: 0px 20px 0px 20px;">
                    <el-table
                        v-loading="loading"
                        :data="recordList"
                        style="width:100%;"
                        :height="tableHeight"
                        border
                    >
                        <el-table-column
                            type="index"
                            label="序号"
                            align="center"
                            width="55"
                            :formatter="Formatter"
                        />
                        <el-table-column prop="type" label="通话类型" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.type == 1">呼入</span>
                                <span v-else>呼出</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createdTime" label="通话日期" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.createdTime|formatDate }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="callerName" label="员工" align="center" />
                        <el-table-column prop="calledName" label="客户姓名" align="center" />
                        <el-table-column prop="calledNumber" label="联系号码" align="center">
                            <template
                                slot-scope="scope"
                            >{{ common.hidePhoneNumber( scope.row.calledNumber ) }}</template>
                        </el-table-column>
                        <el-table-column prop="followContent" label="跟进信息" align="center" />
                        <el-table-column prop="duration" label="通话时长/秒" align="center" />
                        <el-table-column prop="recordUrl" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click.native.prevent="getRecord(scope.$index,scope.row)"
                                >录音</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="call-page">
                    <div>
                        <el-pagination
                            :current-page="currentPage"
                            :page-size="pageSizeNo"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </div>
            </div>

            <div v-if="recordSwitch" class="call-record-content">
                <div class="record-part-item1">
                    <span>播放录音</span>
                    <div class="record-close" @click="recordSwitch = false;">
                        <i class="el-icon-close" />
                    </div>
                </div>
                <div class="record-part-item2">
                    <span>{{ recordItem.callerName }}与{{ recordItem.calledName }}的通话</span>
                    <span>时间：{{ recordItem.createdTime|formatDate }}</span>
                    <span>联系号码：{{ common.hidePhoneNumber( recordItem.calledNumber) }}</span>
                    <div style="margin-top: 20px;">
                        <callAudio :src="recordItem.audioUrl" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { doptions, list } from '../../../assets/js/falseData.js';
import callAudio from '../../../components/callAudio.vue';
import callSelect from '../../../components/callSelect.vue';
import { getYMDHMS } from '../../../assets/js/time.js';
import { callRecord } from '../../../api/callPhone_Infor.js';
import { departments, staff } from '../../../api/public_content.js';

export default {
    data() {
        return {
            input: '',
            timeTabs: 1, //时间段
            valuetime: '', //选择转为时间值
            callSearchChange: true,
            loading: true,
            recordItem: {},
            doptions,
            // list,
            departYuanGong: [],
            keyInput: '',
            pageSizeNo: 10,
            pageTotal: 0,
            recordList: [],
            recordSwitch: false,
            currentPage: 1,
            source: '',
            getStaff: [],
            callRecordStatus: {
                keyword: '',
                departmentId: '',
                staffId: '',
                type: '',
                duration: '',
                startTime: '',
                endTime: '',
                pageNo: 1,
                pageSize: 10,
            },
            inputKeyword: '',
            departmentArr: [],
            callType: [
                {
                    name: '全部',
                    id: 0,
                },
                {
                    name: '呼入未接',
                    id: 1,
                },
                {
                    name: '呼入接通',
                    id: 2,
                },
                {
                    name: '呼出未接',
                    id: 3,
                },
                {
                    name: '呼出接通',
                    id: 4,
                },
            ],
            statusTime: {
                startTime: '',
                endTime: '',
            },
            getCallDuration: [
                {
                    name: '全部',
                    id: 0,
                },
                {
                    name: '1到30秒',
                    id: 1,
                },
                {
                    name: '31到60秒',
                    id: 2,
                },
                {
                    name: '1分钟到3分钟',
                    id: 3,
                },
                {
                    name: '3分钟以上',
                    id: 4,
                },
            ],
            windowinnerHeight: 0,
        };
    },
    computed: {
        tableHeight() {
            return this.windowinnerHeight - 310 + 'px';
        },
    },
    created() {
        this.windowinnerHeight = window.innerHeight;
        window.addEventListener('resize', this.getHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getHeight);
    },
    components: {
        callSelect,
        callAudio,
    },
    methods: {
        getHeight() {
            this.windowinnerHeight = window.innerHeight;
        },
        Formatter(row, column, index) {
            console.log(index, 'row');
        },

        //获取昨天和获取周
        changeTime(le) {
            // console.log(le, 'le')
            // console.log(this.timeTabs, 'timetab')
            const start = new Date(new Date(new Date().toLocaleDateString()));
            const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
            );
            // 获取昨天
            let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
            // 获取周
            let zhou = start.getTime() - 3600 * 1000 * 24 * 6;
            // 获取月
            let yue = start.getTime() - 3600 * 1000 * 24 * 30;

            console.log(start, 'weqwe');
            if (this.timeTabs == 0) this.valuetime = []; // 全部
            if (this.timeTabs == 1) this.valuetime = [start.getTime(), end]; //今日
            if (this.timeTabs == 2) this.valuetime = [zuo, start.getTime() - 1]; // 昨日
            if (this.timeTabs == 3) this.valuetime = [zhou, end]; //最近一周
            if (this.timeTabs == 4) this.valuetime = [yue, end]; //最近一月
            this.statusTime.startTime = this.valuetime[0] / 1000;
            this.statusTime.endTime = this.valuetime[1] / 1000;
            this.getTimeChange(this.statusTime, this.valuetime, this.timeTabs);
            // this.$emit('changeTime', this.statusTime, this.valuetime, this.timeTabs)
            // console.log(this.statusTime,'statusTime')
        },
        // 修改日期时间
        time(val) {
            if (val == null) {
                this.timeTabs = 0;
                this.valuetime = '';
                // this.handleCurrentChange(1);
            } else {
                this.timeTabs = '';
                this.valuetime = val;
                // this.handleCurrentChange(1);
            }
            // this.statusTime.startTime = this.valuetime[0] / 1000;
            // this.statusTime.endTime = this.valuetime[1] / 1000;
            this.callRecordStatus.startTime = this.valuetime[0] / 1000;
            this.callRecordStatus.endTime = this.valuetime[1] / 1000;
            this.loading = true;
            this.getCallRecord();
            // this.$emit('changeDateTime', this.statusTime, this.valuetime, this.timeTabs)
        },

        // 搜索
        callSearch() {
            this.callRecordStatus.keyword = this.input;
            this.loading = true;
            this.callRecordStatus.pageNo = 1;
            this.currentPage = 1;
            this.getCallRecord();
        },

        // 开启录音
        getRecord(index, item) {
            console.log(index, item, '这是查看录音打印的内容');
            this.recordItem = item;
            if (this.recordSwitch == false) {
                this.recordSwitch = true;
            } else {
                this.recordSwitch = false;
            }
        },

        // 每页多少条显示
        handleSizeChange(val) {
            this.pageSizeNo = val;

            this.callRecordStatus.pageSize = this.pageSizeNo;
            this.getCallRecord();
            console.log(`每页 ${val} 条`);
        },

        // 跳转当前页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.loading = true;
            this.callRecordStatus.pageNo = this.currentPage;
            // console.log(this.callRecordStatus.pageNo, 'd')
            console.log(`当前页: ${val}`);
            this.getCallRecord();
        },

        getInput(inputVal) {
            // console.log(this.callRecordStatus.keyword, 'eadd')
            this.inputKeyword = inputVal;
            this.callRecordStatus.keyword = this.inputKeyword;
            this.getCallRecord();
        },

        // 时间转换
        getTimeChange(statusTime, valuetime, timeTab) {
            // console.log(statusTime,'statusTime')
            // console.log(valuetime,'valuetime')
            // console.log(timeTab, 'TIMETAB')
            // console.log(valuetime[0] / 1000, 'valuetime0')
            this.currentPage = 1;
            this.callRecordStatus.pageNo = 1;
            this.callRecordStatus.startTime = valuetime[0] / 1000;
            this.callRecordStatus.endTime = valuetime[1] / 1000;
            // this.callRecordStatus.startTime=valuetime.startTime
            // this.callRecordStatus.endTime=statusTime.endTimea
            console.log();
            this.loading = true;
            this.getCallRecord();
        },
        getCallRecord() {
            callRecord(this.callRecordStatus).then((res) => {
                if (res.code == '0') {
                    console.log(res, 'getCallRecord');
                    this.recordList = res.data.list;
                    this.pageTotal = res.data.total;
                }
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            });
        },

        // 获取部门
        getDepartment(departmentId) {
            departments({}).then((res) => {
                this.departmentArr = res.data.list;
            });
            this.currentPage = 1;
            this.callRecordStatus.pageNo = 1;
            this.callRecordStatus.staffId = '';
            this.callRecordStatus.type = '';
            this.callRecordStatus.duration = '';
            this.callRecordStatus.departmentId = departmentId;
            // if (departmentId === undefined) {departmentId = ''}
            staff({
                departmentId: departmentId,
            }).then((res) => {
                this.getStaff = res.data.list;
                console.log(res, 'staff');
            });
            this.loading = true;
            this.getCallRecord();
        },

        // 获取成员id
        getMenberId(val) {
            this.loading = true;
            this.currentPage = 1;
            this.callRecordStatus.pageNo = 1;
            this.callRecordStatus.type = '';
            this.callRecordStatus.duration = '';
            this.callRecordStatus.staffId = val;
            // console.log(val, 'valmen')
            this.getCallRecord();
        },

        // 获取通话类型
        getCallType(valType) {
            this.loading = true;
            console.log(valType, 'valType');
            this.currentPage = 1;
            this.callRecordStatus.pageNo = 1;
            this.callRecordStatus.duration = '';
            this.callRecordStatus.type = valType;
            this.getCallRecord();
        },

        // 获取通话时长
        getCallDurationChange(Duration) {
            this.loading = true;
            this.currentPage = 1;
            this.callRecordStatus.pageNo = 1;
            this.callRecordStatus.duration = Duration;
            this.getCallRecord();
        },
    },
    filters: {
        formatDate(val) {
            return getYMDHMS(val);
        },
    },
    mounted() {
        // this.getTimeChange()

        // 第一次进入页面的时间
        // const TodaystartTime = new Date();
        // let todayTime=TodaystartTime.getTime(TodaystartTime)
        const TodaystartTime = new Date(
            new Date(new Date().toLocaleDateString())
        );
        const TodayendTime = new Date(
            new Date(new Date().toLocaleDateString()).getTime() +
                24 * 60 * 60 * 1000 -
                1
        );
        this.callRecordStatus.startTime = TodaystartTime.getTime() / 1000;
        this.callRecordStatus.endTime = TodayendTime / 1000;
        console.log(TodayendTime / 1000, 'TodayendTime/1000');
        this.getCallRecord();
        this.getDepartment();
    },
};
</script>

<style lang="less" scoped="scoped">
.content-record {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .call-record-Style {
        height: 100%;
        // width: 100%;
        margin: 0 15px 0 15px;
        // border: 1px solid black;

        .call-table {
            margin-top: 10px;
            // margin-bottom: 20px;
            border-radius: 10px;
            // border: 1px solid black;
            background-color: white;
            overflow-y: scroll;
            // height: 650px;
        }

        .call-record-content {
            position: absolute;
            left: 40%;
            top: 35%;
            width: 386px;
            height: 220px;
            background-color: rgb(64, 134, 236);
            border-radius: 10px;
            z-index: 999;
            color: #ffffff;

            .record-part-item1 {
                width: 100%;
                margin-top: 20px;
                justify-content: center;
                align-items: center;
                // border: 1px solid black;

                display: flex;
                flex-direction: row;
                position: relative;

                .record-close {
                    right: 15px;
                    position: absolute;
                }
            }

            .record-part-item2 {
                margin-top: 15px;
                padding-left: 30px;
                display: flex;
                flex-direction: column;
                line-height: 25px;
            }
        }
    }

    .call-page {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        // border: 1px solid black;
        div {
            margin-right: 30px;
            margin-bottom: 10px;
        }
    }
}

.record-close:hover {
    animation: turn 0.8s linear;
}

@keyframes turn {
    0% {
        -webkit-transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(90deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
    }

    75% {
        -webkit-transform: rotate(270deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

.call-record-selectStyle-down-all {
    // margin-top: 20px;
    // margin-bottom: 20px;
    margin: 20px 20px 0 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .call-search {
        border-radius: 3px;
        box-shadow: 0 0px 2px #999999;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 240px;
        height: 28px;
        cursor: pointer;

        .call-search-icon {
            margin: 0 5px 0 5px;
        }
        /deep/ .el-input__inner {
            height: 24px;
            border: 0px;
            border-right: 1px solid #dcdfe6;
            border-radius: 0px;
        }
    }

    .call-search-time {
        // width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
